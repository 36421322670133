<template>
  <div>
    <div class="fix-top">
      <el-button
        @click="handleCancel()"
        icon="el-icon-arrow-left"
        type="primary"
        >返回</el-button
      >
      <div>
        <el-button
          type="primary"
          @click="handleSave()"
          v-if="showSave"
          :disabled="disabled"
          >保存</el-button
        >
        <el-button type="primary" @click="handleSubmit()" :disabled="disabled"
          >提交</el-button
        >
      </div>
    </div>
    <div style="padding-top: 35px">
      <AppInfo
        ref="appInfoRef"
        @changeType="handleChangeReleaseType"
        :languageList="languageList"
        :appInfoData="appInfoData"
        :operationType="operationType"
      ></AppInfo>
      <VersionInfo
        v-if="releaseType !== 2"
        ref="versionInfoRef"
        :releaseType="releaseType"
        :operationType="operationType"
        :versionInfoData="versionInfoData"
      ></VersionInfo>
      <AppointmentInfo
        v-if="releaseType === 2"
        ref="appointInfoRef"
        :operationType="operationType"
        :languageList="languageList"
        :appointmentInfoData="appointmentInfoData"
      ></AppointmentInfo>
      <ClosedBetaInfo
        v-if="releaseType === 3"
        ref="closedBetaInfoRef"
        :operationType="operationType"
        :closedBetaInfoData="closedBetaInfoData"
      ></ClosedBetaInfo>
    </div>
  </div>
</template>
<script>
import AppInfo from "./AppInfo.vue"; // 应用信息
import VersionInfo from "./VersionInfo.vue"; // 版本信息
import AppointmentInfo from "./AppointmentInfo.vue"; // 预约信息
import ClosedBetaInfo from "./ClosedBetaInfo.vue"; // 内测信息
import { updateReleaseType } from "./indexServe.js";
export default {
  components: { AppInfo, VersionInfo, AppointmentInfo, ClosedBetaInfo },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  created() {
    this.initData(this.detailData);
  },
  data() {
    return {
      appInfoData: {}, // 应用信息
      versionInfoData: {}, // 版本信息
      appointmentInfoData: {}, // 预约信息
      closedBetaInfoData: {}, // 内测信息
      releaseType: 1, // 上架类型：1--普通，2--预约，3--内测，4--首发
    };
  },
  computed: {
    disabled() {
      let data = false;
      if (this.operationType === "view") {
        data = true;
      }
      return data;
    },
    showSave() {
      return this.operationType === "create" || this.operationType === "edit"; //只有新建的时候，可以使用保存
    },
  },
  methods: {
    handleSubmit() {
      if (
        this.detailData.releaseStatus === 1003 &&
        this.releaseType === 3 &&
        this.detailData.releaseType === 3
      ) {
        // 原本上架类型是内测且处于上架状态的应用不能进行修改
        this.$message.error("内测在线状态，不可更改应用信息");
        return;
      }
      const appInfoValid =
        this.$refs.appInfoRef && this.$refs.appInfoRef.validate();
      const versionInfoValid =
        this.$refs.versionInfoRef && this.$refs.versionInfoRef.validate();
      const appointmentInfoValid =
        this.$refs.appointInfoRef && this.$refs.appointInfoRef.validate();
      const closedBetaInfoValid =
        this.$refs.closedBetaInfoRef && this.$refs.closedBetaInfoRef.validate();
      let valid = false;
      let msg = "";
      if (this.releaseType === 1 || this.releaseType === 4) {
        valid = appInfoValid.valid && versionInfoValid.valid;
        msg = appInfoValid.msg + versionInfoValid.msg;
      } else if (this.releaseType === 2) {
        valid = appInfoValid.valid && appointmentInfoValid.valid;
        msg = appInfoValid.msg + appointmentInfoValid.msg;
      } else if (this.releaseType === 3) {
        valid =
          appInfoValid.valid &&
          versionInfoValid.valid &&
          closedBetaInfoValid.valid;
        msg = appInfoValid.msg + versionInfoValid.msg + closedBetaInfoValid.msg;
      }
      if (valid) {
        const formData = this.getFormData();
        this.$emit("submit", formData, this.releaseType);
      } else {
        if (msg) {
          this.$message.error(msg);
        } else {
          this.$message.error("请检查输入的数据");
        }
      }
    },
    handleSave() {
      const formData = this.getFormData();
      this.$emit("save", formData, this.releaseType);
    },
    handleCancel() {
      this.$emit("cancle");
    },
    handleChangeReleaseType(val) {
      this.updateReleaseType(
        {
          sourceType: this.detailData.sourceType,
          releaseType: val, //目标应用上架类型
          toReleaseType: this.releaseType, //当前应用上架类型
          appInfoId: this.detailData.id,
        },
        val
      );
    },
    async updateReleaseType(params, releaseType) {
      try {
        const res = await updateReleaseType(params);
        if (res && res.code === 0) {
          this.releaseType = releaseType;
          this.versionInfoData.apkForm.packageName = res.data;
          this.appointmentInfoData.packageName = res.data;
          if (params.toReleaseType === 2) {
            // 预约类型的应用转换成其他类型的应用，需要将预约的发布国家或地区,赋值给其他应用
            this.versionInfoData.releaseArea =
              this.appointmentInfoData.releaseArea;
            this.closedBetaInfoData.betaReleaseArea =
              this.appointmentInfoData.releaseArea;
          }
          if (params.releaseType === 2) {
            // 其他类型的应用转换成预约类型的应用，需要将其他应用的发布国家或地区,赋值给预约
            this.appointmentInfoData.releaseArea =
              this.versionInfoData.releaseArea;
          }
        } else {
          this.$refs.appInfoRef &&
            this.$refs.appInfoRef.setReleaseType(this.releaseType); // 不支持类型转换，将类型修改回去
        }
      } catch (err) {
        this.$refs.appInfoRef &&
          this.$refs.appInfoRef.setReleaseType(this.releaseType); // 不支持类型转换，将类型修改回去
      }
    },
    // 获取表单信息
    getFormData() {
      const appInfoData =
        this.$refs.appInfoRef && this.$refs.appInfoRef.getFormData();
      const versionInfoData =
        this.$refs.versionInfoRef && this.$refs.versionInfoRef.getFormData();
      const appointmentInfoData =
        this.$refs.appointInfoRef && this.$refs.appointInfoRef.getFormData();
      const closedBetaInfoData =
        this.$refs.closedBetaInfoRef &&
        this.$refs.closedBetaInfoRef.getFormData();
      const data = {
        ...versionInfoData,
        ...closedBetaInfoData,
        ...appointmentInfoData,
        ...appInfoData, // 将应用信息放在结构赋值的最后，以免部分在应用数据修改的数据被覆盖（如默认语言）
      };

      data.defultLanguage = data.defaultLanguage; // 后端接口存在单词拼写错误情况
      if (this.detailData.releaseType == 2) {
        data.releaseInfoId = data.appReleaseId; // 预约管理的发布id
      }
      //当上架类型发生变化时，传给后端的数据需要添加目标类型的字段
      const oldReleaseType = this.detailData.releaseType;
      if (oldReleaseType !== data.releaseType) {
        data.toReleaseType = data.releaseType;
        data.releaseType = oldReleaseType;
      }
      if (JSON.stringify(data.apkForm) == "{}") {
        data.apkForm = undefined; // 不存在包信息是，后端不需要该对象
      }
      return data;
    },

    initData(data) {
      const releaseType = data.releaseType || 1;
      this.releaseType = releaseType;
      const {
        appCategoryId,
        languageInfos = [],
        developerVo = {},
        versionVo = {},
        honorAppReservedApply = {},
        honorAppTestApply = {},
        ...rest
      } = data;
      const localLanguage = (languageInfos || []).map((i) => i.languageId); // 可本地化语言的id列表
      const appClassify = (developerVo.appClassifyIds || "").split(",");
      // 应用基础信息
      const appInfoData = {
        ...rest,
        ...developerVo,
        releaseType, // 应用上架类型
        appName: data.title,
        appType: appCategoryId,
        languageInfos: this.formLanguageInfos(languageInfos),
        appCategory: appClassify[0] ? appClassify[0].split("|") : [],
        gameType: (appClassify[1] && parseInt(appClassify[1])) || 1,
        labels: (developerVo.labels || []).map((i) => i + ""),
        localLanguage,
      };
      this.appInfoData = appInfoData;

      const { extendInfo = {}, ozApk = {}, ...restVersion } = versionVo;
      // 版本信息
      this.versionInfoData = {
        ...rest,
        ...extendInfo,
        ...restVersion,
        releaseType, // 应用上架类型
        releaseArea: this.splitInt(versionVo.country),
        tariffType: this.splitInt(versionVo.inAppPayment, "string"),
        ageLevel: versionVo.ratingId,
        apkId: ozApk.id,
        apkForm: {
          ...ozApk,
          fileSha256: ozApk.fileSha256,
          sha256: ozApk.signSha256,
        },
        ersionPeriod: [extendInfo.startTime || "", extendInfo.endTime || ""],
        normalReleaseType: versionVo.releaseType || 1, //上架时间类型
        normalReleaseTime: versionVo.releaseTime,
      };
      // 预约信息
      this.appointmentInfoData = {
        ...rest,
        ...honorAppReservedApply,
        releaseType, // 应用上架类型
        releaseArea: this.splitInt(honorAppReservedApply.country),
        languagDescFroms: this.formLanguagDescFroms(languageInfos),
      };
      const {
        publishCountry,
        firstLaunchTime,
        startTime = "",
        endTime = "",
        isBill = 1,
        deleteFiles = 0,
        communication,
        returnStrategyMin,
        returnStrategyMax,
        ...restTest
      } = honorAppTestApply;
      // 内测信息
      this.closedBetaInfoData = {
        ...rest,
        ...restTest,
        releaseType,
        betaReleaseArea: this.splitInt(publishCountry),
        betalTime: [startTime, endTime],
        testStartTime: startTime,
        testEndTime: endTime,
        predictTime: firstLaunchTime,
        delFlag: deleteFiles,
        costFlag: isBill,
        minVal: returnStrategyMin,
        maxVal: returnStrategyMax,
        relation: communication,
      };
    },
    formLanguageInfos(lgList) {
      if (!lgList) {
        return [];
      }
      const list = lgList.map((lgObj) => {
        const { imageVos = [], videoVos = [], languageId, ...rest } = lgObj;
        const obj = { ...rest };
        obj.language = languageId;
        obj.imageForms = imageVos;
        obj.videoForms = videoVos;
        const apkImgList = [];
        imageVos.forEach((imgObj) => {
          //imgType图片类型 图标:1，横向截图:2，竖向截图:3
          if (imgObj.imgType === 1) {
            obj.appIcon = imgObj.accessPath;
          } else {
            apkImgList[imgObj.sort] = imgObj.accessPath;
            obj.imgMode = imgObj.imgType === 3 ? "vertical" : "horizontal";
          }
        });
        obj.apkImgList = apkImgList;
        obj.apkImgListLight = [];
        videoVos.forEach((vObj) => {
          //videoType应用介绍视频横向:10，应用介绍视频竖向:11, 推荐视频:12，横向视屏封面:26，纵向视屏封面:27，推荐视屏封面:28
          if ([10, 11].includes(vObj.videoType)) {
            obj.videoMode = vObj.videoType === 11 ? "vertical" : "horizontal";
            obj.appVideoUrl = vObj.accessPath;
            obj.appVideoUrlLight = vObj.accessPathLight;
          } else if ([26, 27].includes(vObj.videoType)) {
            obj.videoMode = vObj.videoType === 27 ? "vertical" : "horizontal";
            obj.appVideoImg = vObj.accessPath;
            obj.appVideoImgLight = vObj.accessPathlight;
          } else if ([12].includes(vObj.videoType)) {
            obj.appPushVideoUrl = vObj.accessPath;
            obj.appPushVideoUrlLight = vObj.accessPathLight;
          } else if ([28].includes(vObj.videoType)) {
            obj.appPushVideoImg = vObj.accessPath;
            obj.appPushVideoImgLight = vObj.accessPathLight;
          }
        });
        return obj;
      });
      return list;
    },
    formLanguagDescFroms(lgList) {
      if (!lgList) {
        return [];
      }
      const list = lgList.map((lgObj) => {
        const { largeImg, expectPublishTime, languageId } = lgObj;
        const obj = {
          largeImg,
          expectPublishTime,
          language: languageId,
        };
        return obj;
      });
      return list;
    },
    splitInt(str = "", stringType) {
      if (!str) {
        return [];
      }
      if (stringType) {
        return str.split("|").map((i) => String(i));
      } else {
        return str.split("|").map((i) => parseInt(i));
      }
    },
  },
  watch: {
    detailData(data) {
      this.initData(data);
    },
  },
};
</script>
<style scoped lang="scss">
.fix-top {
  position: fixed;
  z-index: 99;
  background: #fff;
  padding: 16px 0;
  width: 87%;
  top: 135px;
  display: flex;
  justify-content: space-between;
}
</style>
