<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    size="small"
    label-width="130px"
  >
    <el-form-item label="ratio调整为" prop="ratio">
      <el-input
        v-model="formData.ratio"
        placeholder="请输入0-1之间的小数"
      ></el-input>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" @click="closeBtn">取消</el-button>
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.init(this.detailData);
  },
  data() {
    return {
      formData: {},
      rules: {
        ratio: [{ required: true, message: "请输入0-1之间的小数" }],
      },
    };
  },
  methods: {
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const regex = /^(0|0\.\d{1,2}|1(\.0{1,2})?)$/; // 正则表达式
          if (regex.test(this.formData.ratio)) {
            this.$emit("submit", this.formData);
          } else {
            this.$message.error("请输入0-1之间的小数");
          }
        }
      });
    },
    closeBtn() {
      this.$emit("cancel");
    },
    init(data) {
      this.formData = {
        ...data,
        ratio: data.ratio || "",
      };
    },
  },
  watch: {
    detailData(data) {
      this.init(data);
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 50%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
}
</style>
